// extracted by mini-css-extract-plugin
export var Annotation = "styles-module--Annotation--W7M92";
export var Container = "styles-module--Container--kdLtG";
export var Description = "styles-module--Description--D+Sib";
export var FeeTable = "styles-module--FeeTable--hMzvl";
export var FeeTable_FeeBody_Data = "styles-module--FeeTable_FeeBody_Data--+XAQq";
export var FeeTable_FeeBody_Plan = "styles-module--FeeTable_FeeBody_Plan--OkOEz";
export var FeeTable_FeeHead_Thread = "styles-module--FeeTable_FeeHead_Thread--p4Eai";
export var ProductCosts = "styles-module--ProductCosts--Cz3DR";
export var ProductCosts_Data = "styles-module--ProductCosts_Data--FDVES";
export var ProductCosts_Thread = "styles-module--ProductCosts_Thread--zjoqZ";
export var RegistrationFee = "styles-module--RegistrationFee--jhcOF";
export var RegistrationFee_Data = "styles-module--RegistrationFee_Data--FaqKh";
export var RegistrationFee_Thread = "styles-module--RegistrationFee_Thread--HC42v";
export var Title = "styles-module--Title--LjGPN";