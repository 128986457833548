import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import * as Titles from '@arch-log/webapp.shared/Title'
import * as SectionsComponents from './Sections'
import * as Literals from './Literals'
import * as DefaultComponents from './DefaultComponents'

/**
 */
const Title = ({
  components: { Container = DefaultComponents.TitleContainer } = {},
}) => (
  <Container>
    <Titles.StaticPageTitle>
      <Literals.Title />
    </Titles.StaticPageTitle>
  </Container>
)

/**
 */
const Header = withDefaultRenderComponent(
  ({ components: { Container = DefaultComponents.HeaderContainer } = {} }) => {
    return (
      <Container>
        <Title />
      </Container>
    )
  },
)

const Body = withDefaultRenderComponent(
  ({
    children,
    components: { Container = DefaultComponents.BodyContainer },
  }) => {
    return <Container>{children}</Container>
  },
)

/**
 */
const Sections = withDefaultRenderComponent(
  ({
    components: { Container = DefaultComponents.SectionsContainer } = {},
  }) => {
    return (
      <Container>
        <SectionsComponents.FirstSection />
        <SectionsComponents.SecondSection />
        <SectionsComponents.ThirdSection />
        <SectionsComponents.FourthSection />
      </Container>
    )
  },
)

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Container = DefaultComponents.Container,
      Header: headerComponents,
      Body: bodyComponents,
    } = {},
  }) => {
    return (
      <Container>
        <Header components={headerComponents} />
        <Body components={bodyComponents}>
          <Sections />
        </Body>
      </Container>
    )
  },
)
