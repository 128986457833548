import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as DefaultComponents from './DefaultComponents'

/**
 */
export const FirstSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Table = DefaultComponents.PublishFeeTable,
    Head = DefaultComponents.PublishFeeHead,
    Thread = DefaultComponents.PublishFeeThread,
    Body = DefaultComponents.PublishFeeBody,
    Row = DefaultComponents.PublishFeeRow,
    Data = DefaultComponents.PublishFeeData,
    Plan = DefaultComponents.PublishFeePlan,
  } = {},
}) => (
  <Container>
    <Title>掲載価格</Title>
    <Table>
      <Head>
        <Row>
          <Thread>月額掲載料金プラン</Thread>
          <Thread>登録商品点数</Thread>
          <Thread>月額基本掲載料</Thread>
        </Row>
      </Head>
      <Body>
        <Row>
          <Plan>ライトプラン</Plan>
          <Data>1点~</Data>
          <Data>￥50,000</Data>
        </Row>
        <Row>
          <Plan>ベーシックプラン</Plan>
          <Data>501点~</Data>
          <Data>￥80,000</Data>
        </Row>
        <Row>
          <Plan>ボリュームプラン</Plan>
          <Data>1,001点~</Data>
          <Data>￥120,000</Data>
        </Row>
        <Row>
          <Plan>アンリミテッドプラン</Plan>
          <Data>5,001点~</Data>
          <Data>￥240,000</Data>
        </Row>
      </Body>
    </Table>
  </Container>
)

/**
 */
export const SecondSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
    Table = DefaultComponents.ProductCostsTable,
    Body = DefaultComponents.ProductCostsBody,
    Row = DefaultComponents.ProductCostsRow,
    Thread = DefaultComponents.ProductCostsThread,
    Data = DefaultComponents.ProductCostsData,
  } = {},
}) => (
  <Container>
    <Title>商品データ制作費</Title>
    <Description>別途お見積り致します</Description>
    <Table>
      <Body>
        <Row>
          <Thread>カタログデータ制作料</Thread>
          <Data>1商品あたり</Data>
          <Data>￥500~ ※最低価格表示・条件あり</Data>
        </Row>
        <Row>
          <Thread>BIMデータ制作料</Thread>
          <Data>1商品あたり</Data>
          <Data>提供資料、データ内容を元に見積致します</Data>
        </Row>
      </Body>
    </Table>
  </Container>
)

/**
 */
export const ThirdSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
    Table = DefaultComponents.RegistrationFeeTable,
    Body = DefaultComponents.RegistrationFeeBody,
    Row = DefaultComponents.RegistrationFeeRow,
    Thread = DefaultComponents.RegistrationFeeThread,
    Data = DefaultComponents.RegistrationFeeData,
    Annotation = DefaultComponents.Annotation,
  } = {},
}) => (
  <Container>
    <Title>データ登録料</Title>
    <Description>商品情報を弊社サーバーへ登録します</Description>
    <Table>
      <Body>
        <Row>
          <Thread>カタログデータ登録料</Thread>
          <Data>1商品登録毎</Data>
          <Data>￥100</Data>
        </Row>
        <Row>
          <Thread>BIMデータ登録料</Thread>
          <Data>1商品登録毎</Data>
          <Data>￥200</Data>
        </Row>
      </Body>
    </Table>
    <Annotation>※契約期間は1年単位となります。</Annotation>
    <Annotation>※初回契約時に初期設定費用10万円が必要です。</Annotation>
    <Annotation>
      ※初期設定費用には初回のカタログデータ制作・登録料と入稿代行（最大500点まで）が含まれております。
    </Annotation>
  </Container>
)

/**
 */
export const FourthSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Application</Title>
    <Description>
      Please contact us from
      <Links.Contact.ToTop subject={'Inquiries about Arch-LOG'}>
        here.
      </Links.Contact.ToTop>
    </Description>
  </Container>
)
