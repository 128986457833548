import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => {
  return <div className={styles.Container}>{children}</div>
}

/**
 */
export const HeaderContainer = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const BodyContainer = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const SectionsContainer = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const TitleContainer = ({ children }) => {
  return <div className={styles.TitleContainer}>{children}</div>
}
