import React from 'react'

import { Link } from '@arch-log/webapp.shared/Link'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => {
  return <div className={styles.Container}>{children}</div>
}

/**
 */
export const Title = ({ children }) => {
  return <dt className={styles.Title}>{children}</dt>
}

/**
 */
export const Description = ({ children }) => {
  return <dd className={styles.Description}>{children}</dd>
}

/**
 */
export const PublishFeeTable = ({ children }) => {
  return <table className={styles.FeeTable}>{children}</table>
}

/**
 */
export const PublishFeeHead = ({ children }) => {
  return <thead className={styles.FeeTable_FeeHead}>{children}</thead>
}

/**
 */
export const PublishFeeBody = ({ children }) => {
  return <tbody className={styles.FeeTable_FeeBody}>{children}</tbody>
}

/**
 */
export const PublishFeeThread = ({ children }) => {
  return <th className={styles.FeeTable_FeeHead_Thread}>{children}</th>
}

/**
 */
export const PublishFeeRow = ({ children }) => {
  return <tr>{children}</tr>
}

/**
 */
export const PublishFeeData = ({ children }) => {
  return <td className={styles.FeeTable_FeeBody_Data}>{children}</td>
}

/**
 */
export const PublishFeePlan = ({ children }) => {
  return <td className={styles.FeeTable_FeeBody_Plan}>{children}</td>
}

/**
 */
export const ProductCostsTable = ({ children }) => {
  return <table className={styles.ProductCosts}>{children}</table>
}

/**
 */
export const ProductCostsBody = ({ children }) => {
  return <tbody className={styles.ProductCosts_Body}>{children}</tbody>
}

/**
 */
export const ProductCostsRow = ({ children }) => {
  return <tr>{children}</tr>
}

/**
 */
export const ProductCostsThread = ({ children }) => {
  return <th className={styles.ProductCosts_Thread}>{children}</th>
}

/**
 */
export const ProductCostsData = ({ children }) => {
  return <td className={styles.ProductCosts_Data}>{children}</td>
}

/**
 */
export const RegistrationFeeTable = ({ children }) => {
  return <table className={styles.RegistrationFee}>{children}</table>
}

/**
 */
export const RegistrationFeeBody = ({ children }) => {
  return <tbody className={styles.RegistrationFee_Body}>{children}</tbody>
}

/**
 */
export const RegistrationFeeRow = ({ children }) => {
  return <tr>{children}</tr>
}

/**
 */
export const RegistrationFeeThread = ({ children }) => {
  return <th className={styles.RegistrationFee_Thread}>{children}</th>
}

/**
 */
export const RegistrationFeeData = ({ children }) => {
  return <td className={styles.RegistrationFee_Data}>{children}</td>
}

/**
 */
export const Annotation = ({ children }) => {
  return <dd className={styles.Annotation}>{children}</dd>
}
