import React from 'react'

import * as config from '@arch-log/webapp.modules/config/config'

/**
 */
export const Default = () => {
  const trackingId = config.get('list_finder.tracking_id')

  if (!trackingId) {
    return null
  }

  return (
    <script type="text/javascript">
      {`
            var _trackingid = '${trackingId}';(function() {
                var lft = document.createElement('script'); lft.type = 'text/javascript'; lft.async = true;
                lft.src = ('https:' == document.location.protocol ? 'https:' : 'http:') + '//track.list-finder.jp/js/ja/track.js';
                var snode = document.getElementsByTagName('script')[0]; snode.parentNode.insertBefore(lft, snode);
            })();
        `}
    </script>
  )
}
