import React from 'react'

import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

import * as SectionJa from './Section.ja'
import * as SectionEn from './Section.en'

/**
 */
export const FirstSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.FirstSection {...props} />
    default:
      return <SectionJa.FirstSection {...props} />
  }
})

/**
 */
export const SecondSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.SecondSection {...props} />
    default:
      return <SectionJa.SecondSection {...props} />
  }
})

/**
 */
export const ThirdSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.ThirdSection {...props} />
    default:
      return <SectionJa.ThirdSection {...props} />
  }
})

/**
 */
export const FourthSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.FourthSection {...props} />
    default:
      return <SectionJa.FourthSection {...props} />
  }
})
